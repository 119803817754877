/**
 * The types to show a datetime or date/time input.
 */
const DATE_TIME_TYPES = ['time-message-check', 'date-time-message-check'];

/**
 * The types to display in the first Subject dropdown.
 */
const EVENT_TYPES = [
  'event',
  'nlp-fallback-event',
  'nlp-event',
  'message-event',
  'error',
  'event-date-time-check',
  'semantic-search-event',
  'generative-ai-event',
  ...DATE_TIME_TYPES
];

/**
 * The types to show a condition input.
 */
const CONDITION_TYPES = [
  'message-check',
  'message-number-check',
  'message-group-check',
  'message-intent-check',
  'message-faq-check'
];

/**
 * The types to show after the user select a subject (Message or Making an API request).
 */
const CHECK_TYPES = [...CONDITION_TYPES, 'message-type'];

const formatGroup = ({ label, options }) => {
  return {
    label: label.replace(/-/g, ' '),
    options
  };
};

const groupReducer = (groups, conditional) => {
  const index = groups.findIndex(group => group.label === conditional.type);

  groups[index].options = groups[index].options.concat({
    label: conditional.name || conditional.label,
    value: conditional.name || conditional.value,
    type: conditional.type,
    isDisabled: Boolean(conditional.isDisabled)
  });

  return groups;
};

const group = conditionals => {
  return conditionals
    .reduce(
      groupReducer,
      [
        ...new Set(conditionals.map(conditional => conditional.type))
      ].map(label => ({ label, options: [] }))
    )
    .map(formatGroup);
};

const format = conditional => {
  if (!conditional) {
    return null;
  }

  return {
    label: conditional.name,
    value: conditional.name,
    type: conditional.type
  };
};

const isEventType = type => EVENT_TYPES.includes(type);

const isCheckType = type => CHECK_TYPES.includes(type);

const isConditionType = type => CONDITION_TYPES.includes(type);

const isDateTimeType = type => DATE_TIME_TYPES.includes(type);

const hasTimeCondition = type => type === 'time-message-check';

const hasDateTimeCondition = type => type === 'date-time-message-check';

const hasNumberCondition = type => {
  return ['integration-number-check', 'message-number-check'].includes(type);
};

const hasGroupCondition = type => type === 'message-group-check';

const hasTextCondition = type => {
  return !hasNumberCondition(type) && !hasGroupCondition(type);
};

const showCondtionals = type => isCheckType(type);

const showCondition = type => isConditionType(type);

export {
  formatGroup,
  groupReducer,
  group,
  format,
  EVENT_TYPES,
  CONDITION_TYPES,
  CHECK_TYPES,
  DATE_TIME_TYPES,
  isEventType,
  isCheckType,
  isConditionType,
  isDateTimeType,
  hasNumberCondition,
  hasGroupCondition,
  hasTextCondition,
  hasDateTimeCondition,
  hasTimeCondition,
  showCondtionals,
  showCondition
};
